.form-control,
.form-select {
    box-shadow: none !important;
}
.form-control[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #545a6d;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #545a6d;
}

::-webkit-scrollbar-thumb {
    background-color: #545a6d;
    border: 2px solid #727285;
}

.modal .base-table {
    table {
        td,
        th {
            border: 1px solid #4a4646;
            vertical-align: middle;
            max-width: 50%;
            word-wrap: break-word;
        }
    }
}
.base-table {
    max-width: 100%;
    max-height: 80vh;
    overflow: auto;
    table {
        text-align: center;
        thead {
            background-color: #3f3f50;
            position: sticky;
            top: -0.5px;
            left: 0;
            vertical-align: middle;
            z-index: 1;
        }
        td,
        th {
            border: 1px solid #31323c;
            vertical-align: middle;
            max-inline-size: 600px;
        }
    }

    .w100 {
        width: 100px;
        min-width: 100px;
    }
    .w120 {
        width: 120px;
        min-width: 120px;
    }

    .w150 {
        width: 150px;
        min-width: 150px;
    }
    .w60 {
        width: 60px;
        min-width: 60px;
    }
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}

.dark-theme .pagination {
    --cui-pagination-active-border-color: rgba(255, 255, 255, 0.075);
    --cui-pagination-active-bg: #3f3f50;
    a {
        cursor: pointer;
    }
    &.pagination-sm {
        .page-link {
            min-width: 28px;
            text-align: center;
        }
    }
}

.clink {
    cursor: pointer;
}

.sm-input {
    max-width: 100%;
    text-align: center;
}

.btn-action {
    display: inline-flex;
    cursor: pointer;
    padding: 5px;
}

.disabled {
    pointer-events: none;
}

.text-green {
    color: #77c551;
}

.nav-groups {
    .nav-link {
        padding: 5px 30px;
        border: 1px solid;
        margin: 2px;
        font-size: 14px;
    }
}

.flex-box-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.react-select-container {
    & > div {
        background-color: #3f3f50;
        color: white;
        .css-nnzyhz-singleValue {
            color: white;
        }
        [data-value] {
            color: white;
        }
    }
}

#grid-aggregation {
    text-align: center;
    vertical-align: middle;

    .col-checkbox {
        width: 40px;
        max-width: 40px;
        text-align: center;
    }
    .logo {
        height: 60px;
        width: 150px;
        object-fit: cover;
    }
    label.form-control {
        min-width: 100px;
    }
    .w150 {
        width: 150px;
        max-width: 150px;
    }
    tr.row-danger {
        .form-control {
            background: #241e1e;
        }
    }
}

.row-enable-aggregation {
    background: #181212;
}
.bg-enable-aggregation {
    .modal-content {
        background: #2a2a2a;
    }
}

.item-auto-skewing {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    img {
        margin: 0 10px;
    }
}

.logo-exchange {
    height: 60px;
    width: 150px;
}

.bg-orange {
    background-color: var(--orange);
}
.ring-container {
    position: relative;
    display: inline-block;
    margin-right: 5px;
}

.circle {
    width: 15px;
    height: 15px;
    background-color: #62bd19;
    border-radius: 50%;
}

.ringring {
    border: 3px solid #62bd19;
    -webkit-border-radius: 30px;
    height: 25px;
    width: 25px;
    position: absolute;
    left: -5px;
    top: -5px;
    -webkit-animation: pulsate 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    opacity: 0;
}
@-webkit-keyframes pulsate {
    0% {
        -webkit-transform: scale(0.1, 0.1);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1.2, 1.2);
        opacity: 0;
    }
}

#dashboard-widget {
    .card {
        .card-header {
            text-align: center;
            font-size: 21px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .card-body {
            display: flex;
            flex-wrap: wrap;
            .card-col {
                flex: 50%;
                max-width: 50%;
                height: 90px;
                justify-content: center;
                align-items: center;
                display: flex;
                flex-direction: column;
            }
            .text-number {
                font-size: 16px;
            }
        }
    }
}

.form-control:disabled {
    background-color: #5f6368;
    border-color: #b1b7c1;
    opacity: 1;
}
.fadeIn {
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.table-fixed-column {
    thead th {
        z-index: 1;
    }
    thead th:first-child,
    tbody td:first-child {
        position: sticky;
        left: 0;
        z-index: 9;
    }
    tbody td:first-child {
        background-color: #24252f;
    }
}

.ring-container {
    .circle {
        &.offline {
            background-color: #d8d1d1;
        }
    }
}
